import { RatePlanCodes } from '@dx-ui/framework-uri-builder';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import type { QueryParameters } from '../constants';

export const getLengthOfStay = (departureDate?: string, arrivalDate?: string) =>
  arrivalDate && departureDate
    ? differenceInCalendarDays(parseISO(departureDate), parseISO(arrivalDate)) || 1
    : 1;

export const isSpecialRatesDeeplinked = (queryParameters: QueryParameters | undefined) => {
  if (!queryParameters) return false;

  const { token } = queryParameters;

  const rateConditions = [
    queryParameters.aaaRate,
    queryParameters.aarpRate,
    queryParameters.employeeRate,
    queryParameters.friendsAndFamilyRate,
    queryParameters.gvmtRates,
    queryParameters.governmentOrMilitaryRate,
    queryParameters.ownerVIPRate,
    queryParameters.ownerHGVRate,
    queryParameters.seniorRate,
    queryParameters.smbRate,
    queryParameters.travelAgentRate,
  ];

  const tokenConditions = [
    token?.includes(RatePlanCodes.AAA),
    token?.includes(RatePlanCodes.AARP),
    token?.includes(RatePlanCodes.GOVERNMENT_MILITARY),
    token?.includes(RatePlanCodes.SENIOR),
  ];

  return rateConditions.some(Boolean) || tokenConditions.some(Boolean);
};
