import { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { SortByDropDownValues, LabelValue } from '../utils';
import {
  SORTBY_DROP_DOWN,
  getSortByDropDownOptions,
  isSortByPoints,
  isSortByPrice,
  getSortByOnUsePointsChange,
  getGuestInfo,
} from '../utils';
import { useGetUsePoints } from './use-get-usePoints';
import { useGetMVTSelections } from './use-get-mvt-selections';
import { useWrappedRouter } from './use-wrapped-router';
import { useAppDispatch, useAppState } from '../providers/app-provider';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { CHANGE_DEFAULT_SORT_WHEN_USING_POINTS, VARIANTS } from '../constants';
import { useFilterState } from '../providers/filter-provider';

type GetSortByProps = {
  filteredHotelsCount?: number;
  isInvalidOrUnavailableOffer?: boolean;
  isBrandPage?: boolean;
};
export const useGetSortByOptions = ({
  filteredHotelsCount,
  isInvalidOrUnavailableOffer,
}: GetSortByProps) => {
  const { sortType, shouldUsePoints, pageType, hasSpecialRate } = useAppState();
  const activeFiltersState = useFilterState();
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const dispatch = useAppDispatch();
  const { guestInfo, isLoading: isAuthLoading } = useAuth();
  const { t } = useTranslation('sort-by-drop-down');

  const { isDisabled: isPointsDisabled } = useGetUsePoints(isInvalidOrUnavailableOffer);
  const isPointsSortOptionsDisabled = isPointsDisabled || hasSpecialRate;
  const isTMTPUserAndHasTMRate = useMemo(() => {
    const { isTMTPUser } = getGuestInfo(guestInfo);
    const hasTMTPRate =
      queryParameters?.employeeRate ||
      queryParameters?.friendsAndFamilyRate ||
      queryParameters?.ownerVIPRate;
    return (
      router.isReady &&
      !isAuthLoading &&
      isTMTPUser &&
      hasTMTPRate &&
      filteredHotelsCount &&
      filteredHotelsCount <= 150
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryParameters), filteredHotelsCount, isAuthLoading]);

  //NHCSEARCH-5020 Change Default Sort When Using Points
  const pointsSortMvt = useGetMVTSelections({
    agentId: CHANGE_DEFAULT_SORT_WHEN_USING_POINTS?.agentId,
    isProvisional: shouldUsePoints,
  });
  const isPointsSortMVT =
    pointsSortMvt?.isLoaded && pointsSortMvt?.isMVTVariant?.[VARIANTS.VariantB];
  // Handle route change
  useEffect(() => {
    let sortBy = sortType;
    if (queryParameters?.sortBy) {
      if (isTMTPUserAndHasTMRate) sortBy = SORTBY_DROP_DOWN.PRICE_ASCENDING;
    }
    if (sortBy !== sortType) {
      dispatch({ type: 'SET_SORT_TYPE', payload: sortBy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTMTPUserAndHasTMRate, queryParameters?.sortBy]);

  const resetSortForPointsDisabled = useMemo(() => {
    let resetSort = sortType;
    //isPoints disabled - reset sort by to corresponding price
    if (isPointsSortOptionsDisabled && isSortByPoints(sortType)) {
      if (sortType === SORTBY_DROP_DOWN.POINTS_ASCENDING) {
        resetSort = SORTBY_DROP_DOWN.PRICE_ASCENDING;
      } else if (sortType === SORTBY_DROP_DOWN.POINTS_DESCENDING) {
        resetSort = SORTBY_DROP_DOWN.PRICE_DESCENDING;
      }
    }
    return resetSort;
  }, [isPointsSortOptionsDisabled, sortType]);

  const isPriceSortOptionsDisabled =
    !!((filteredHotelsCount && filteredHotelsCount > 150) || pageType.isPointsExplorer) ||
    !!queryParameters?.datesFlex;

  const sortByOptions = useMemo(() => {
    const sortByOptionsList = getSortByDropDownOptions(t);
    //reset sortby options
    sortByOptionsList.forEach((options: LabelValue) => {
      if (isSortByPoints(options.value)) {
        options.isDisabled = !!(
          isPointsSortOptionsDisabled ||
          isPriceSortOptionsDisabled ||
          (shouldUsePoints && hasSpecialRate)
        );
      } else if (isSortByPrice(options.value)) {
        options.isDisabled = isPriceSortOptionsDisabled;
      }
    });
    return sortByOptionsList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeFiltersState.brandFilters,
    filteredHotelsCount,
    isPointsSortOptionsDisabled,
    sortType,
    queryParameters?.datesFlex,
    hasSpecialRate,
  ]);

  useEffect(() => {
    let resetSortType = resetSortForPointsDisabled;
    // Reset to DISTANCE or BRAND when  Price/Points options are disabled
    if (
      (isPriceSortOptionsDisabled && isSortByPrice(sortType)) ||
      (isPointsSortOptionsDisabled && isSortByPoints(sortType))
    ) {
      resetSortType = SORTBY_DROP_DOWN.DISTANCE;
    }
    if (resetSortType !== sortType) dispatch({ type: 'SET_SORT_TYPE', payload: resetSortType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPriceSortOptionsDisabled,
    isPointsSortOptionsDisabled,
    resetSortForPointsDisabled,
    router.isReady,
    sortType,
  ]);

  // reset sort type when usePoints changes
  useEffect(() => {
    if (isSortByPrice(sortType) || isSortByPoints(sortType)) {
      const resetSortType = getSortByOnUsePointsChange(sortType, shouldUsePoints, !!hasSpecialRate);
      if (resetSortType !== sortType)
        dispatch({ type: 'SET_SORT_TYPE', payload: resetSortType as SortByDropDownValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUsePoints, sortType, hasSpecialRate]);

  //NHCSEARCH-5020 Change Default Sort When Using Points
  const hotelCountCondition = pageType.isSearch
    ? filteredHotelsCount && filteredHotelsCount <= 150
    : true;
  useEffect(() => {
    if (
      isPointsSortMVT &&
      shouldUsePoints &&
      hotelCountCondition &&
      !isSortByPoints(sortType) &&
      !queryParameters?.datesFlex
    )
      dispatch({ type: 'SET_SORT_TYPE', payload: 'POINTS_ASCENDING' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPointsSortMVT, shouldUsePoints, hotelCountCondition]);

  return { sortByOptions };
};
