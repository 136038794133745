import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { InfoPopup } from '@dx-ui/osc-info-popup';
import CurrencySelector from './currency-converter.selector';
import cx from 'classnames';
import type { GetCurrencyConverterCurrenciesQuery } from '@dx-ui/queries-osc-currency-converter';

type CurrencyDropDownProps = {
  showDefaultOption?: boolean;
  currencies: GetCurrencyConverterCurrenciesQuery;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedCurrency: string;
  hasStackedDisplay?: boolean;
  selectProps?: Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    'onChange' | 'value'
  >;
};
const CurrencyDropDown = ({
  showDefaultOption,
  currencies,
  onChange,
  selectedCurrency,
  hasStackedDisplay,
  selectProps,
}: CurrencyDropDownProps) => {
  const { t } = useTranslation('osc-currency-converter');
  const hasNoCurrency = Boolean(!selectedCurrency);

  return (
    <div className="items-center text-sm" data-testid="currencyConverter">
      <div
        className={cx('flex text-xs font-semibold tracking-tight md:text-sm rtl:mx-0', {
          'flex-col': hasStackedDisplay,
        })}
      >
        <span className={cx('flex items-center ltr:pr-3 rtl:pl-3', { 'pb-1': hasStackedDisplay })}>
          <label htmlFor={selectProps?.id || ''} className="whitespace-nowrap">
            {t('selectCurrency')}
          </label>
          {/* Currency Disclaimer and tooltip */}
          <span className="ml-1 inline-flex align-text-top rtl:mr-1">
            <InfoPopup label={t('currencyDisclaimer')}>
              <span className="flex w-56 whitespace-normal">{t('currencyDisclaimerContent')}</span>
            </InfoPopup>
          </span>
        </span>
        <CurrencySelector
          currencies={currencies}
          onChange={onChange}
          selectedCurrency={selectedCurrency}
          selectProps={selectProps}
        >
          {hasNoCurrency || showDefaultOption ? (
            <option
              data-testid="currency-converter-option-LOCAL"
              key="currencyOptionLocal"
              value=""
            >
              {t('local')}
            </option>
          ) : null}
        </CurrencySelector>
      </div>
    </div>
  );
};
export default CurrencyDropDown;
